.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.vertical-timeline::before {
  /* this is the vertical line */

  background: black !important;
}

.empty {
  height: 600px;
  border: 1px solid red;
}

/* Custom CSS */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Apply the animation to the rotating element */
.rotate {
  animation: spin 2s linear infinite; /* Change the duration (2s) to adjust the rotation speed */
}



@media (min-width: 320px) {
  .sm\:pt-4 {
      padding-top: 3rem /* 16px */;
  }

  .introtext {
    margin-top: 40px;
    font-size: 22px;
  }
}